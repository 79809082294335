import React, { useState } from "react";
import "../css/subscribe.css";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const SubscriptionPage = () => {
  const handleSubmit = () => {
    alert(
      "Congratulations on your successful subscription, our system will record your subscription. Please contact us further to discuss service subscription details!"
    );
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />
      <div className="subscription_page">
        <div className="sub_inner">
          <h1>Subscribe to Cross-Border Logistics Updates</h1>
          <div className="sub_body">
            <div className="sub_inner_body">
              <div className="sub_title">Basic Logistics Updates</div>
              <div className="sub_bodt_content">
                <div className="sub_price">$9.9</div>
                <div className="sub_btn">
                  <button onClick={handleSubmit}>Subscribe to</button>
                </div>
                <div className="sub_btn">
                  <Link to="/contact" onClick={toTop}>
                    Contact Us
                  </Link>
                </div>
                <div className="sub_includes">Includes:</div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Weekly logistics updates
                </div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Access to basic logistics information
                </div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Email notifications for major updates
                </div>
              </div>
            </div>
            {/* two */}
            <div className="sub_inner_body">
              <div className="sub_title">Premium Logistics Updates</div>
              <div className="sub_bodt_content">
                <div className="sub_price"> $19.99</div>
                <div className="sub_btn">
                  <button onClick={handleSubmit}>Subscribe to</button>
                </div>
                <div className="sub_btn">
                  <Link to="/contact" onClick={toTop}>
                    Contact Us
                  </Link>
                </div>
                <div className="sub_includes">Includes:</div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Daily logistics updates
                </div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Priority access to detailed logistics data
                </div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Real-time tracking and alerts
                </div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Personalized logistics advice
                </div>
              </div>
            </div>
            {/* three */}
            <div className="sub_inner_body">
              <div className="sub_title">Elite Logistics Updates</div>
              <div className="sub_bodt_content">
                <div className="sub_price">$39.99</div>
                <div className="sub_btn">
                  <button onClick={handleSubmit}>Subscribe to</button>
                </div>
                <div className="sub_btn">
                  <Link to="/contact" onClick={toTop}>
                    Contact Us
                  </Link>
                </div>
                <div className="sub_includes">Includes:</div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Hourly logistics updates
                </div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Exclusive access to advanced logistics analytics
                </div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Priority customer support
                </div>
                <div className="sub_includes_body">
                  <div>
                    <picture>
                      <img
                        role="presentation"
                        loading="lazy"
                        fetchpriority="auto"
                        className="builder-image css-150qet9"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5213aeabec8662f03994551238d02b1d045600075981d6a102f5cfba88e838d?placeholderIfAbsent=true&width=16 16w"
                        sizes="2vw"
                      />
                    </picture>
                    <div className="builder-image-sizer css-781yh5"> </div>
                  </div>
                  Customized logistics solutions
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SubscriptionPage;
